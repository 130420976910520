
import { Component, Vue } from 'vue-property-decorator'
import { fileService, AppFile } from '@/services/file.service'
import { settledFolderService, SettledFolder } from '@/services/settledFolder.service'
import { quoteService, Quote } from '@/services/quote.service'

@Component({
  subscriptions (this: Vue) {
    return {
      files$: fileService.onChange,
      settledFolders$: settledFolderService.onChangeFolders,
      quote$: quoteService.onChangeQuote
    }
  }
})
export default class QuoteDialogs extends Vue {
  files$: AppFile[] = []
  settledFolders$: SettledFolder[] = []
  quote$: Quote
  showingSelectFile: boolean = false
  showingAcceptValidation: boolean = false
  showingComment: boolean = false
  showingConfirmDelete: boolean = false
  showingConfirmAssociation: boolean = false
  showingSelectFolder: boolean = false
  showSelectFileToTransform: boolean = false

  callback: any = {}

  selectFileForm: any = {
    selectedFile: null,
    comment: null
  }

  selectFileFormRules: any = {
    selectedFile: [
      { required: true, message: 'Veuillez sélectionner une lettre de mission', trigger: 'change' }
    ]
  }

  commentForm: any = {
    comment: null
  }

  commentFormRules: any = {
    comment: [
      { required: true, message: 'Merci d’ajouter un commentaire', trigger: 'change' }
    ]
  }

  selectFolderForm: any = {
    selectedFolder: null
  }

  selectFolderFormRules: any = {
    selectedFile: [
      { required: true, message: 'Veuillez sélectionner un dossier', trigger: 'change' }
    ]
  }

  get lmFiles (): AppFile[] {
    return this.files$.filter((file: AppFile) => file.type === 'Lettre de mission' || file.type === 'LM')
  }

  showSelectFileDialog (callback: any) {
    this.showingSelectFile = true
    this.callback = callback
  }

  submitSelectFileForm () {
    (this.$refs.selectFileForm as any).validate((valid: boolean) => {
      if (valid) {
        const signers = this.quote$.contacts.filter((contact: any) => contact.signer)
        const followers = this.quote$.contacts.filter((contact: any) => contact.follower)
        let signersName = ''
        if (signers.length > 0) {
          signersName = signers.map((signer: any) => {
            return signer.contact ? signer.contact.nom + ' ' + signer.contact.prenom : null
          }).join(', ')
        }
        let followersName = ''
        if (followers.length > 0) {
          followersName = followers.map((follower: any) => {
            return follower.contact ? follower.contact.nom + ' ' + follower.contact.prenom : null
          }).join(', ')
        }
        this.$confirm('Attention une fois la lettre de mission validée pour signature Yousign, celle-ci sera envoyée aux contacts suivants : <br>' +
          'Signataire(s) : ' + signersName + '<br> Suiveur(s) : ' + followersName, '', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'Confirmation',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }).then(() => {
          this.showingSelectFile = false
          this.callback(this.selectFileForm.selectedFile, this.selectFileForm.comment)
        }).catch()
      }
    })
  }

  cancelSelectFile () {
    this.showingSelectFile = false
    this.showSelectFileToTransform = false
    this.callback(null)
  }

  showAcceptValidationDialog (callback: any) {
    this.showingAcceptValidation = true
    this.callback = callback
  }

  acceptValidation () {
    this.showingAcceptValidation = false
    this.callback(true)
  }

  cancelAccept () {
    this.showingAcceptValidation = false
    this.callback(false)
  }

  showCommentDialog (callback: any) {
    this.showingComment = true
    this.callback = callback
  }

  submitCommentForm () {
    (this.$refs.commentForm as any).validate((valid: boolean) => {
      if (valid) {
        this.showingComment = false
        this.callback(this.commentForm.comment)
      }
    })
  }

  cancelComment () {
    this.showingComment = false
    this.callback(null)
  }

  showConfirmDeleteDialog (callback: any) {
    this.showingConfirmDelete = true
    this.callback = callback
  }

  confirmDelete () {
    this.showingConfirmDelete = false
    this.callback(true)
  }

  cancelDelete () {
    this.showingConfirmDelete = false
    this.callback(false)
  }

  showConfirmAssociationDialog (callback: any) {
    this.showingConfirmAssociation = true
    this.callback = callback
  }

  confirmAssociation () {
    this.showingConfirmAssociation = false
    this.callback(true)
  }

  cancelAssociation () {
    this.showingConfirmAssociation = false
    this.callback(false)
  }

  showSelectFolderDialog (callback: any) {
    this.selectFolderForm.selectedFolder = quoteService.quote.associateFolderId
    this.showingSelectFolder = true
    this.callback = callback
  }

  submitSelectFolderForm () {
    (this.$refs.selectFolderForm as any).validate((valid: boolean) => {
      if (valid) {
        this.showingSelectFolder = false
        this.callback(this.selectFolderForm.selectedFolder)
      }
    })
  }

  removeSelectFolder () {
    if (quoteService.quote.associateFolderId) {
      quoteService.unassociateToFolder()
    }
    this.selectFolderForm.selectedFolder = null
  }

  searchFolder (query: string) {
    settledFolderService.searchFolders([{ name: query }], 0, 10)
  }

  updateShowSelectFileToTransform (callback: any) {
    this.showSelectFileToTransform = true
    this.callback = callback
  }

  submitSelectFileToTransform () {
    (this.$refs.selectFileForm as any).validate((valid: boolean) => {
      if (valid) {
        this.showSelectFileToTransform = false
        this.callback(this.selectFileForm.selectedFile)
      }
    })
  }
}
